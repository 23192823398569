import * as React from "react"
import { Link } from "gatsby"

const Footer = () => (
  <footer>
    <div class="container">
      <div class="column">
        <img class="logo mg-b-16" src="/smartop-logo-grey.svg" alt="logo"></img>
        <p class="company-about mg-b-16">
          SmartOp est une jeune startup francaise créée par des médecins anesthésistes-réanimateurs et
          ingénieurs dans le but de répondre aux besoins d’organisation du bloc
          opératoire…
        </p>

        <div class="mg-b-16 _subtitle">
          <Link class="" to="/equipe">En savoir plus</Link>
        </div>

        <div>
          <Link className="privacy-policy" to="/politique-de-confidentialite">
            Politique de confidentialité
          </Link>
        </div>
      </div>

      <div class="column">
        <h5 class="title mg-b-16">Nous contacter</h5>
        <div class="_subtitle">
        <div class="mg-b-10">+33 01 76 39 12 45</div>
        <div class="mg-b-10">+33 07 57 90 17 63</div>
          <div class="mg-b-16">contact@smartop.io</div>

          <div class="contact-row">
            <div class="icon margin-right-10px">
              <a href="https://www.linkedin.com/company/smartopsas/" target="_blank"
                title="LinkedIn">
                <img src="/linkedin-icon.png"></img>
              </a>
            </div>
            <div class="icon">
              <a href="https://www.welcometothejungle.com/fr/companies/smartop" target="_blank"
                title="Welcome to the Jungle">
                <img src="/welcome-to-the-jungle-icon.png"></img>
              </a>
            </div>
          </div>
        </div>
        
      </div>

      <div class="column">
        <h5 class="title mg-b-16">Où nous trouver ?</h5>
        <div class="_subtitle">
          <a>63 rue Ordener 75018, Paris, France</a>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
