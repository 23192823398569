/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "../css/sass/main.scss"
import Footer from "./footer"
import { Helmet } from "react-helmet"
import useSiteMetadata from "./../hooks/use-site-meta-data"

const Layout = ({ children }) => {
  const { title, description } = useSiteMetadata()
  return (
    <>
      <div class="website-wrapper">
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        <Header />
        <div
          style={{
            margin: `0 auto`,
            maxWidth: `100%`,
          }}
        >
          <main>{children}</main>
          <Footer />
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
