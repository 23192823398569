import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import PopupConnexion from "./popupConnexion"

const Header = () => {
  const [openPopup, setOpenPopup] = useState(false)
  
  const getWidth = () => {
    if (typeof window !== 'undefined') {
      return window.innerWidth;
    }
    return;
  }

  let pages = [
    { label: "Accueil", link: "/" },
    { label: "Produit", link: "/produit" },
    { label: "Équipe", link: "/equipe" },
    { label: "Actualités", link: "/actualités" },
  ]

  if (getWidth() <= 900)
    pages.push({ label: "Contact", link: "/contact" } , { label: "Connexion", link: "#" })

  const getCurrentPage = (page) => 
  {
    if (typeof window !== 'undefined') {
      return window.location.pathname.substr(0, 5) === page.substr(0, 5);
    }
  }

  const ProductLink = {
    name: "Privée",
    link: "https://product.smartop.io/",
    IOSLink: "https://apps.apple.com/fr/app/smartop-app/id1525284023",
    androidLink: "https://play.google.com/store/apps/details?id=io.smartOp.app&gl=FR"
  }

  const buttonConnexionClickedHandler = e => {
    e.preventDefault();
    if (typeof window !== "undefined") {
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.includes("iphone") || userAgent.includes("ipad")) {
        window.open(ProductLink.IOSLink, "_blank");
      } else if (userAgent.includes("android")) {
        window.open(ProductLink.androidLink, "_blank");
      } else {
        window.open(ProductLink.link, "_blank");
      }
    }
    // setOpenPopup(openPopup => !openPopup)
  };  

  let popup = null
  if (openPopup) {
    popup = (
      <PopupConnexion closeButtonClicked={buttonConnexionClickedHandler} />
    )
  }

  return (
    <>
      {popup}
      <div class="section master-first-content spartan ls-half">
        <div class="menu-logo">
          <Link to="/">
            <img src="/smartop-logo.svg" alt="logo" />
          </Link>
        </div>
        <div class="mainMenu" id="mainMenu">
          <ul class="level1 static" role="menubar">
            {pages.map(({ label, link }) => (
              <li role="menuitem">
                <Link className={`level1 static ${getCurrentPage(link) ? 'current-page' : ''}`} to={link}>
                  {label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <a onClick={buttonConnexionClickedHandler} class="connexion" href="#">
          Connexion
        </a>
        <a className="contact-us" href="/contact">
          Nous contacter
        </a>
      </div>

      <div class="navigation">
        <input class="checkbox" id="nav_toggle" type="checkbox" />
        <label for="nav_toggle" class="toggle">
          <span class="toggle-icon"></span>
        </label>
        <div class="background"></div>
        <nav class="nav">
          <ul class="list">
            {pages.map(({ label, link }) => (
              <li class="item u-margin-bottom-small">
                <Link onClick={link === '#' ? buttonConnexionClickedHandler : ''} to={link} class="link" activeClassName="link">
                  {label}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

export default Header
