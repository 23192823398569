import React, { useState } from "react"
import { toast } from "react-toastify"

const PopupConnexion = ({ closeButtonClicked }) => {
  const [hospitalIndex, setHospital] = useState(null)
  const HospitalsLinks = [
    {
      name: "Sélectionner un type de structure",
      link: "#"
    },
    {
      name: "Publique",
      link: "https://application.smartop.io/",
      IOSLink: "https://apps.apple.com/fr/app/smartop-public/id1562901091",
      androidLink: "https://play.google.com/store/apps/details?id=io.larib.app"
    },
    {
      name: "Privée",
      link: "https://product.smartop.io/",
      IOSLink: "https://apps.apple.com/fr/app/smartop-app/id1525284023",
      androidLink: "https://play.google.com/store/apps/details?id=io.smartOp.app&gl=FR"
    }
  ]
  const inputChangedHandler = e => {
    setHospital(e.target.value)
  }

  const buttonConnexionClickedHandler = () => {
    if (hospitalIndex && hospitalIndex != 0 && typeof window !== "undefined") {
      window.open(HospitalsLinks[hospitalIndex].link, "_blank")
    }
  }

  const popupClickedHandler = e => {
    if (e.target.classList.value === "popup") {
      closeButtonClicked(e)
    }
  }

  const openApplication = (platform) => {
    if (!hospitalIndex || hospitalIndex == 0) {
      return;
    }

    if (platform === "IOS") {
      window.location.href = HospitalsLinks[hospitalIndex].IOSLink;
    } else if (platform === "Android")
      window.location.href = HospitalsLinks[hospitalIndex].androidLink;
  }

  return (
    <div class="popup popup-container" id="popup" onClick={popupClickedHandler}>
      <div class="content">
        <div className="title">Connexion</div>

        <label className="select-label" for="Fonction">Type de structure *</label>
        <select value={hospitalIndex} type="text" class="input w-input select" onChange={inputChangedHandler}>
          {HospitalsLinks.map((hospitalLink, index) => (
            <option value={index}>{hospitalLink.name}</option>
          ))}
        </select>

        <div className="action">
          <a onClick={closeButtonClicked} type="button" className="cancel-btn">Annuler</a>
          <a onClick={buttonConnexionClickedHandler} type="button"
             className="connect-btn">Connexion</a>
        </div>

        <div className="sub-action">
          <div className="or-container">
            <div className="separator"></div>
            <div className="or-element">ou</div>
            <div className="separator"></div>
          </div>

          <div className="app-buttons-container">
            <div>
              <img onClick={function() {openApplication("IOS")}} className="mg-b-16" height="120px" src="/ios-btn.svg" alt="ios button" />
            </div>
            <div>
              <img onClick={function() {openApplication("Android")}} className="mg-b-16" height="120px" src="/android-btn.png" alt="android button" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopupConnexion
